import { Routes } from '@angular/router';
import { EmptyRouteComponent } from './empty-route/empty-route.component';
import { msDataResolver } from './resolvers/ms-data-resolver.resolver';
import { TemplateComponent } from './pages/private/template/template.component';
import { RolesComponent } from './pages/private/roles/roles.component';
export const routes: Routes = [
  {
    path: 'manage-platform',
    resolve:{
      msData: msDataResolver
    },
    children: [
      {
        path: 'roles',
        component:RolesComponent,
      }
    ],
  },
  { path: '**', component: EmptyRouteComponent },
];
