import { CommonModule } from '@angular/common';
import { Component, OnInit, signal, viewChild } from '@angular/core';
import { FormBuilder, ReactiveFormsModule, Validators } from '@angular/forms';
import { UiButtonComponent } from '@components/ui/ui-button/ui-button.component';
import * as UiDropdwon from '@components/ui/ui-dropdown'
import { UiInputComponent } from '@components/ui/ui-input/ui-input.component';
import { ICapabilities, IPlatformModule } from '@models/platform-module';
import { IRole } from '@models/role';
import { ApiService } from '@services/api-service.service';
import { debounceTime, distinctUntilChanged, filter, forkJoin, map, of, Subject, switchMap, tap } from 'rxjs';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-capabilities',
  standalone: true,
  imports: [
    UiDropdwon.Root,
    UiDropdwon.Trigger,
    UiDropdwon.Content,
    UiDropdwon.Item,
    UiInputComponent,

    ReactiveFormsModule,
    UiButtonComponent,
    CommonModule
  ],
  templateUrl: './capabilities.component.html',
  styleUrl: './capabilities.component.css'
})
export class CapabilitiesComponent implements OnInit {
  constructor(private _fb: FormBuilder, private _apiService: ApiService){}

  public capabilitiesForm = this._fb.group({
    roleID: ['', Validators.required],
    platformModuleID: ['', Validators.required],
    canSee: [false],
    canEdit: [false],
    canCreate: [false],
    canApprove: [false],
    canDelete: [false],
  })

  public roles = signal<IRole[]>([]);
  public _mainRoles = signal<IRole[]>([]);
  private _roleSearchInput = viewChild<UiInputComponent>("roleSearchInput");
  private _searchModuleToAdd$:Subject<string> = new Subject();
  
  private _moduleSearchInput = viewChild<UiInputComponent>("moduleSearchInput");
  public modules = signal<IPlatformModule[]>([]);

  public showDialog = signal<boolean>(false);
  public capabilities = signal<ICapabilities[]>([]);
  public capabilityToEdit = signal<ICapabilities | null>(null);

  private _searchByRol$:Subject<string> = new Subject();
  private _searchByUrl$:Subject<string> = new Subject();

  private _searchByRolValue = signal<string>('');
  private _searchByUrlValue = signal<string>('');

  ngOnInit(): void {
    Swal.fire({
      title:"Cargando...",
      allowEscapeKey: false,
      allowOutsideClick: false,
      didOpen: () => Swal.showLoading()
    })

    this._searchModuleToAdd$.pipe(
      debounceTime(300),
      filter(value => value.length > 3),
      distinctUntilChanged(),
      switchMap((value) => this._apiService.get<IPlatformModule>({
        path: 'PlatformModules', filter: {
          limit: 5,
          where: { or: [{ parentRoute: { regexp: `/${value}/i` } }, { childRoute: { regexp: `/${value}/i` } }] }
        }
      }))
    ).subscribe((value) => {
      this.modules.set(value);
    });

    this._searchByUrl$.pipe(
      debounceTime(300),
      filter(value => value.length > 3),
      distinctUntilChanged(),
      switchMap((value) => {
        this._searchByUrlValue.set(value);
        return this.applyFilters()
      })
    ).subscribe(() => {});

    this._searchByRol$.pipe(
      debounceTime(300),
      filter(value => value.length > 3),
      distinctUntilChanged(),
      switchMap((value) => {
        this._searchByRolValue.set(value);
        return this.applyFilters()
      })
    ).subscribe(() => {});

    forkJoin({
      roles: this._apiService.get<IRole>({ path: 'roles' }),
      capabilities: this.applyFilters()
    }).subscribe({
      next: ({roles}) => {
        this._mainRoles.set(roles);
        this.roles.set(roles);
        Swal.close();
      }, error: (err) => {
        console.log(err);
        Swal.close();
      }
    })
  }

  public deleteCapability(itemId: number) {
    Swal.fire({
      icon: 'info',
      title: '¿Estás seguro?',
      text: 'Esta acción eliminará la capacidad de forma permanente.',
      confirmButtonText: 'Sí, eliminar',
      cancelButtonText: 'Cancelar',
      allowEscapeKey: false,
      allowOutsideClick: false,
      showCancelButton: true,
      showConfirmButton: true
    }).then(resp => {
      if (resp.isConfirmed) {
        Swal.fire({
          title: "Eliminando...",
          allowEscapeKey: false,
          allowOutsideClick: false,
          didOpen: () => Swal.showLoading()
        })
        this._apiService.delete({
          path: `Capabilities/${itemId}`
        }).pipe(
        ).subscribe({
          next: () => {
            Swal.fire({
              icon: 'success',
              title: '¡Operación Exitosa!',
              text: 'La capacidad ha sido eliminada correctamente.',
              allowEscapeKey: false,
              allowOutsideClick: false,
            })
            const newCapabilities = this.capabilities().filter(e => e.id !== itemId);
            this.capabilities.set(newCapabilities);
          },
          error: (error) => {
            Swal.fire({
              icon: 'error',
              title: '¡Error!',
              text: 'Hubo un problema al eliminar la capacidad.',
              showConfirmButton: true
            })
            console.log(error);
          }
        })
      }
    })
  }

  public saveCapability() {
    Swal.fire({
      title:"Guardando...",
      allowEscapeKey:false,
      allowOutsideClick:false,
      didOpen: () => Swal.showLoading()
    })

    this._apiService.put({
      path: 'capabilities',
      data: {
        id: this.capabilityToEdit()?.id ?? null,
        ...this.capabilitiesForm.value
      }
    }).pipe(
      switchMap(() => this.applyFilters())
    ).subscribe({
      next: () => {
        Swal.fire({
          icon:'success',
          title: '¡Operación Exitosa!',
          text: 'La capacidad ha sido guardada correctamente.',
          allowEscapeKey:false,
          allowOutsideClick:false,
        }).then((resp) => {
          if(resp.isConfirmed)
            this.resetForm();
        })
      }, error: (err) => {
        Swal.fire({
          icon:'error',
          title: '¡Error!',
          text: 'Hubo un problema al guardar la capacidad.',
          showConfirmButton: true
        })
        console.log(err);
      }
    })
  }

  public filterRoles(event:Event){
    const value = (event.target as HTMLInputElement).value.toLowerCase();
    const filteredRoles = this._mainRoles().filter(e => e.name.toLowerCase().includes(value));
    this.roles.set(filteredRoles);
  }

  public getModuleValueToAdd(event:Event){
    const value = (event.target as HTMLInputElement);
    this._searchModuleToAdd$.next(value.value);
  }

  public setRoleToAdd(event: { value: string, label: string }) {
    this.capabilitiesForm.patchValue({ roleID: event.value });
    const input = this._roleSearchInput()?.ref.nativeElement.querySelector("input");
    input.value = event.label;
  }

  public setModuleToAdd(event:{value:string, label:string}){
    this.capabilitiesForm.patchValue({platformModuleID: event.value});
    const input = this._moduleSearchInput()?.ref.nativeElement.querySelector("input");
    input.value = event.label;
  }

  public getRoleSearchValue(event:Event){
    const value = (event.target as HTMLInputElement);
    this._searchByRol$.next(value.value);
  }

  public getUrlSearchValue(event:Event){
    const value = (event.target as HTMLInputElement).value;
    this._searchByUrl$.next(value);
  }

  private applyFilters() {
    return forkJoin({
      byRoles: this._searchByRolValue() !== '' ? this.queryByRol() : of([]),
      byUrl: this._searchByUrlValue() !== '' ? this.queryByUrl() : of([])
    }).pipe(
      switchMap(({byRoles, byUrl}) => this.getPlatformModules(byRoles, byUrl).pipe(
        tap(resp => {
          this.capabilities.set(resp.sort((a,b) => a.role!.name.localeCompare(b.role!.name)))
        })
      ))
    )
  }

  private queryByRol(){
    return this._apiService.get<IRole>({
      path: "roles",
      filter: {
        where: {
          name: { regexp: `/${this._searchByRolValue()}/i` }
        }
      }
    }).pipe(
      map((value) => value.map((role) => role.id) as number[])
    );
  }

  private queryByUrl(){
    //TODO: CREAR UNA VISTA PERSONALIZADA QUE DEVULVA EL PARENT ROUTE Y EL CHILD ROUTE COMBINADOS COMO URL
    return this._apiService.get<IPlatformModule>({
      path: "PlatformModules",
      filter: {
        where: {
          parentRoute: { regexp: `/${this._searchByUrlValue()}/i` }
        }
      }
    }).pipe(
      map((value) => value.map((module) => module.id) as number[])
    )
  }

  private getPlatformModules(roleIds:number[], platformModuleIds:number[]){
    const filters:Record<string, any> = {and:[]};

    if(this._searchByRolValue().length > 0)
      filters['and'].push({roleID: {inq: roleIds}});

    if(this._searchByUrlValue().length > 0 )
      filters['and'].push({platformModuleID: {inq: platformModuleIds}});

    return this._apiService.get<ICapabilities>({
      path: "capabilities",
      filter: {
        include: ['PlatformModule', "role"],
        where: { ...filters }
      }
    })
  }

  public editCapability(capability: ICapabilities) {
    this.capabilityToEdit.set(capability);
    this.capabilitiesForm.patchValue({
      ...capability,
      roleID: capability.roleID.toString(),
      platformModuleID: capability.platformModuleID.toString()
    });

    const roleInput = this._roleSearchInput()?.ref.nativeElement.querySelector('input');
    const moduleInput = this._moduleSearchInput()?.ref.nativeElement.querySelector('input');
    roleInput.value = capability.role?.name;
    moduleInput.value = `${capability.PlatformModule?.parentRoute}/${capability.PlatformModule?.childRoute}`;
    this.showDialog.set(true);
  }

  public resetForm(){
    this.capabilitiesForm.reset();
    this.capabilityToEdit.set(null);
    this.showDialog.set(false);

    const roleInput = this._roleSearchInput()?.ref.nativeElement.querySelector('input');
    const moduleInput = this._moduleSearchInput()?.ref.nativeElement.querySelector('input');
    roleInput.value = "";
    moduleInput.value = "";
  }
}