<div class="capabilities">
  <div class="capabilities__filters">
    <ui-input variant="bordered" label="Buscar rol" (input)="getRoleSearchValue($event)" />
    <ui-input variant="bordered" label="Buscar url" (input)="getUrlSearchValue($event)" />
  </div>

  <div class="capabilities-header">
    <h1 class="capabilities-header__title">Capacidades</h1>
    <button class="button button--solid" (click)="showDialog.set(true)">Agregar Capacidad</button>
  </div>

  <div class="capabilities__table-container">
    <table class="capabilities__table">
      <thead>
        <tr>
          <th>Nombre del rol</th>
          <th>Módulo</th>
          <th [ngStyle]="{'text-align': 'center'}">Ver</th>
          <th [ngStyle]="{'text-align': 'center'}">Aprobar</th>
          <th [ngStyle]="{'text-align': 'center'}">Crear</th>
          <th [ngStyle]="{'text-align': 'center'}">Editar</th>
          <th [ngStyle]="{'text-align': 'center'}">Eliminar</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        @for (capability of capabilities(); track $index) {
        <tr>
          <td>{{capability.role?.name}}</td>
          <td>{{capability.PlatformModule?.parentRoute}}/{{capability.PlatformModule?.childRoute}}</td>
          <td>
            @if(capability.canSee){
              <ng-container [ngTemplateOutlet]="check" />
            }
          </td>
          <td>
            @if(capability.canApprove){
              <ng-container [ngTemplateOutlet]="check" />
            }
          </td>
          <td>
            @if(capability.canCreate){
              <ng-container [ngTemplateOutlet]="check" />
            }
          </td>
          <td>
            @if(capability.canEdit){
              <ng-container [ngTemplateOutlet]="check" />
            }
          </td>
          <td>
            @if(capability.canDelete){
              <ng-container [ngTemplateOutlet]="check" />
            }
          </td>
          <td>
            <div class="table-actions">
              <em class="icon-i-pen" (click)="editCapability(capability)"></em>
              <em class="icon-i-trash" (click)="deleteCapability(capability.id!)"></em>
            </div>
          </td>
        </tr>
        }
      </tbody>
      @if(capabilities().length === 0){
        <caption class="capabilities-table__caption">Sin resultados.</caption>
      }
    </table>
  </div>

  <ng-template #check>
    <div class="allowed-icon">
      <em class="icon-i-chulo"></em>
    </div>
  </ng-template>

  <div class="dialog__container" [hidden]="!showDialog()">
    <form class="dialog__content" [formGroup]="capabilitiesForm" (ngSubmit)="saveCapability()">
      <div class="dialog__header">
        <h2 class="dialog__header-title">{{capabilityToEdit() ? 'Editar' : "Agregar"}} Módulo</h2>
        <em class="icon-i-close dialog__header-close" (click)="resetForm()"></em>
      </div>

      <div class="dialog__body">
        <div>
          <ui-dropdown (onValueChange)="setRoleToAdd($event)">
            <ui-dropdown-trigger>
              <ui-input (input)="filterRoles($event)"
                variant="bordered" 
                label="Nombre del rol"
                placeholder="Escriba el nombre del rol"
                #roleSearchInput
              />
            </ui-dropdown-trigger>
            <ui-dropdown-content>
              @for (item of roles(); track $index) {
                <ui-dropdown-item 
                  [value]="item.id!"
                  [disabled]="item.id!.toString() == capabilitiesForm.get('roleID')?.value"
                >
                  {{item.name}}
                </ui-dropdown-item>
              }@empty {
                Sin resultados.
              }
            </ui-dropdown-content>
          </ui-dropdown>
        </div>

        <div>
          <ui-dropdown (onValueChange)="setModuleToAdd($event)">
            <ui-dropdown-trigger>
              <ui-input (input)="getModuleValueToAdd($event)" variant="bordered" label="Selecciona módulo(url)"
                placeholder="Escriba la ruta padre o hija" #moduleSearchInput/>
            </ui-dropdown-trigger>
            <ui-dropdown-content>
              @for (item of modules(); track $index) {
                <ui-dropdown-item 
                  [value]="item.id!" 
                  [disabled]="item!.id.toString() == capabilitiesForm.get('platformModuleID')?.value">
                  {{item.parentRoute}}/{{item.childRoute}}
                </ui-dropdown-item>
              }@empty {
                Sin resultados.
              }
            </ui-dropdown-content>
          </ui-dropdown>
        </div>

        <div class="dialog__body-item">
          <h2 class="dialog__body-title">Seleccionar acciones</h2>
          <div class="dialog__body-content">
            <div class="checkbox-container">
              <label class="custom-checkbox">
                <input hidden type="checkbox" formControlName="canSee">
                <span class="checkmark"></span>
              </label>
              Ver
            </div>
            <div class="checkbox-container">
              <label class="custom-checkbox">
                <input hidden type="checkbox" formControlName="canEdit">
                <span class="checkmark"></span>
              </label>
              Editar
            </div>
            <div class="checkbox-container">
              <label class="custom-checkbox">
                <input hidden type="checkbox" formControlName="canCreate">
                <span class="checkmark"></span>
              </label>
              Crear
            </div>
            <div class="checkbox-container">
              <label class="custom-checkbox">
                <input hidden type="checkbox" formControlName="canApprove">
                <span class="checkmark"></span>
              </label>
              Aprobar
            </div>
            <div class="checkbox-container">
              <label class="custom-checkbox">
                <input hidden type="checkbox" formControlName="canDelete">
                <span class="checkmark"></span>
              </label>
              Eliminar
            </div>
          </div>
        </div>
      </div>

      <ui-button color="primary" [disabled]="capabilitiesForm.invalid">Guardar</ui-button>
    </form>
  </div>
</div>