import { Component, signal } from '@angular/core';
import { ROLE_TABS, RoleTabs } from '@utils/role-tabas';
import { ModulesComponent } from './modules/modules.component';
import { CapabilitiesComponent } from './capabilities/capabilities.component';

@Component({
  selector: 'app-roles',
  standalone: true,
  imports: [ModulesComponent, CapabilitiesComponent ],
  templateUrl: './roles.component.html',
  styleUrl: './roles.component.css'
})
export class RolesComponent {
  public ROLE_TABS:RoleTabs[] = ROLE_TABS;
  public selectedTab = signal<string>('modulos');
}