<div class="modules">
  <div class="modules-header">
    <div class="search-container">
      <ui-input variant="bordered" label="Buscar por ruta padre o hija" (input)="searchModule($event)" />
    </div>
    <button class="button button--solid" (click)="showDialog.set(true)">Agregar URL</button>
  </div>

  <div class="modules__table-container">
    <table class="modules__table">
      <thead> 
      <tr>
        <th>Ruta padre</th>
        <th>Ruta hija</th>
        <th>Grupo</th>
        <th>Nombre Link</th>
        <th>Icono</th>
        <th>Mostrar en menú</th>
        <th></th>
      </tr>
    </thead>
    <tbody>
      @for (module of modules(); track module.id) {
        <tr>
          <td>{{module.parentRoute}}</td>
          <td>{{module.childRoute}}</td>
          <td>{{module.groupName}}</td>
          <td>{{module.linkName}}</td>
          <td>{{module.iconName}}</td>
          <td>{{module.showInMenu ? 'Si' : 'No'}}</td>
          <td>
            <div class="table-actions">
              <em class="icon-i-pen" (click)="editModule(module)"></em>
              <em class="icon-i-trash" (click)="deleteModule(module)"></em>
            </div>
          </td>
        </tr>
      }
    </tbody>
    @if(modules().length === 0){
      <caption class="modules-table__caption">No se encontraron módulos</caption>
    }
    </table>
  </div>

  @if (showDialog()) {
    <div class="dialog__container">
      <form class="dialog__content" [formGroup]="modulesForm" (ngSubmit)="onSave()">
        <div class="dialog__header">
          <h2 class="dialog__header-title">{{moduleToEdit() ? 'Editar' : "Agregar"}} URL</h2>
          <em class="icon-i-close dialog__header-close" (click)="resetForm()"></em>
        </div>
  
        <div class="dialog__body">
          <div>
            <ui-input variant="bordered" label="Nombre de la ruta padre*" formControlName="parentRoute"
              [invalid]="modulesForm.get('parentRoute')?.invalid && modulesForm.get('parentRoute')?.touched" />
            @if (modulesForm.get('parentRoute')?.invalid && modulesForm.get('parentRoute')?.touched) {
              <small class="validation-feedback validation-feedback--text">Este campo es obligatorio.</small>
            }
          </div>

          <div>
            <ui-input variant="bordered" label="Nombre de la ruta hija*" formControlName="childRoute"
              [invalid]="modulesForm.get('childRoute')?.invalid && modulesForm.get('childRoute')?.touched" />
            @if (modulesForm.get('childRoute')?.invalid && modulesForm.get('childRoute')?.touched) {
              <small class="validation-feedback validation-feedback--text">Este campo es obligatorio.</small>
            }
          </div>

          <div>
            <ui-input variant="bordered" label="Nombre del grupo*" formControlName="groupName"
              [invalid]="modulesForm.get('groupName')?.invalid && modulesForm.get('groupName')?.touched" />
            @if (modulesForm.get('groupName')?.invalid && modulesForm.get('groupName')?.touched) {
              <small class="validation-feedback validation-feedback--text">Este campo es obligatorio.</small>
            }
          </div>

          <div>
            <ui-input variant="bordered" label="Nombre del link*" formControlName="linkName"
              [invalid]="modulesForm.get('linkName')?.invalid && modulesForm.get('linkName')?.touched" />
            @if (modulesForm.get('linkName')?.invalid && modulesForm.get('linkName')?.touched) {
              <small class="validation-feedback validation-feedback--text">Este campo es obligatorio.</small>
            }
          </div>

          <div>
            <ui-input variant="bordered" label="Ícono css*" formControlName="iconName"
              [invalid]="modulesForm.get('iconName')?.invalid && modulesForm.get('iconName')?.touched" />
            @if (modulesForm.get('iconName')?.invalid && modulesForm.get('iconName')?.touched) {
              <small class="validation-feedback validation-feedback--text">Este campo es obligatorio.</small>
            }
          </div>

          <div class="checkbox-container">
            <label class="custom-checkbox">
              <input hidden type="checkbox" formControlName="showInMenu">
              <span class="checkmark"></span>
            </label>
            Mostrar en menú
          </div>
        </div>
        <ui-button color="primary" [disabled]="modulesForm.invalid">Guardar</ui-button>
      </form>
    </div>
  }
</div>