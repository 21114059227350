export interface RoleTabs{
  id:string;
  label:string
}

export const ROLE_TABS:RoleTabs[] = [
  {
    label: 'Módulos (url)',
    id: 'modulos',
  },
  {
    label: 'Capacidades',
    id: 'capacidades',
  }
];