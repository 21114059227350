import { Component, OnInit, signal } from '@angular/core';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { UiButtonComponent } from '@components/ui/ui-button/ui-button.component';
import { UiInputComponent } from '@components/ui/ui-input/ui-input.component';
import { IPlatformModule } from '@models/platform-module';
import { ApiService } from '@services/api-service.service';
import { debounceTime, distinctUntilChanged, filter, of, Subject, SubjectLike, switchMap, tap } from 'rxjs';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-modules',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    UiInputComponent,
    UiButtonComponent
  ],
  templateUrl: './modules.component.html',
  styleUrl: './modules.component.css'
})
export class ModulesComponent implements OnInit {
  constructor(private _fb: FormBuilder, private _api: ApiService) { }

  public showDialog = signal<boolean>(false);
  public moduleToEdit = signal<IPlatformModule | null>(null);
  public modules = signal<IPlatformModule[]>([]);
  public modulesForm: FormGroup = this._fb.group({
    parentRoute: ['', [Validators.required]],
    childRoute: ['', [Validators.required]],
    groupName: ['', [Validators.required]],
    linkName: ['', [Validators.required]],
    iconName: ['', [Validators.required]],
    showInMenu: ['', [Validators.required]],
  });
  private _searchValue$: Subject<string> = new Subject();

  private _callOnce = signal<boolean>(false);

  ngOnInit() {
    Swal.fire({
      title: 'Cargando módulos...',
      text: "Por favor espera...",
      showCancelButton: false,
      allowOutsideClick: false,
      didOpen: () => Swal.showLoading()
    });

    this._searchValue$.pipe(
      debounceTime(300),
      distinctUntilChanged(),
      switchMap((value) => {
        if (value.length > 2) {
          this._callOnce.set(true);
          return this.applyFilter(value);
        } else if (this._callOnce()) {
          this._callOnce.set(false);
          return this.applyFilter();
        }
        return of(this.modules());
      })
    ).subscribe((value) => this.modules.set(value));

    this.applyFilter().subscribe({
      next: (resp) => {
        this.modules.set(resp)
        Swal.close();
      },
      error: (err) => {
        console.log(err);
        Swal.close();
      }
    });
  }

  public resetForm(): void {
    this.modulesForm.reset();
    this.moduleToEdit.set(null);
    this.showDialog.set(false);
  }

  public searchModule(event: Event): void {
    const input = event.target as HTMLInputElement;
    this._searchValue$.next(input.value);
  }

  public onSave(): void {
    if(this.modulesForm.valid){
      Swal.fire({
        title: 'Guardando...',
        text: "Por favor espera...",
        allowOutsideClick: false,
        didOpen: () => Swal.showLoading()
      });

      this._api.put({
        path: `PlatformModules`,
        data: {
          id: this.moduleToEdit()?.id ?? null,
          ...this.modulesForm.value,
        }
      }).pipe(
        switchMap(() => this.applyFilter())
      ).subscribe({
        next: (modules) => {
          Swal.fire({
            title: 'Guardado',
            text: "El módulo ha sido guardado",
            icon: 'success',
          }).then(() => {
            this.modules.set(modules);
            this.resetForm();
          });
        },
        error: (err) => {
          console.log(err);
          Swal.fire({
            title: 'Error',
            text: `No se pudo guardar el módulo: ${err.error.error.message || 'Error desconocido'}`,
            icon: 'error',
          });
        }
      });
    }
  }

  public deleteModule(module: IPlatformModule) {
    return Swal.fire({
      title: '¿Estás seguro?',
      text: "No podrás revertir esto",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Si, eliminar'
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire({
          title: 'Eliminando...',
          text: "Por favor espera...",
          allowOutsideClick: false,
          didOpen: () => Swal.showLoading()
        });

        this._api.delete({
          path: `PlatformModules/${module.id}`,
        }).subscribe({
          next: (_) => {
            Swal.fire({
              title: 'Eliminado',
              text: "El módulo ha sido eliminado",
              icon: 'success',
            });
            this.resetForm();
            const updatedModules = this.modules().filter((m) => m.id !== module.id);
            this.modules.set(updatedModules);
          },
          error: (err) => {
            console.log(err);
            Swal.fire({
              title: 'Error',
              text: `No se pudo eliminar el módulo: ${err.error.error.message || 'Error desconocido'}`,
              icon: 'error',
            });
          }
        });
      }
    });
  }

  private applyFilter(value?:string) {
    const filters: Record<string, any> = {};

    if (value)
      filters['or'] = [{ parentRoute: { regexp: `/${value}/i` } }, { childRoute: { regexp: `/${value}/i` } }];

    return this.modulesQuery(filters);
  }

  private modulesQuery(filters: Record<string, any>) {
    return this._api.get<IPlatformModule>({
      path: 'PlatformModules',
      filter: {
        where: { ...filters, orderBy:"parentRoute	ASC" }
      }
    });
  }

  public editModule(module: IPlatformModule) {
    this.moduleToEdit.set(module);
    this.modulesForm.patchValue(module);
    this.showDialog.set(true);
  }
}