import { inject } from '@angular/core';
import { ResolveFn, Router } from '@angular/router';
import { IPlatformModule } from '@models/platform-module';
import { ISchool } from '@models/school';
import { IUser } from '@models/users';
import { ApiService } from '@services/api-service.service';
import { catchError, map, Observable, of, switchMap, tap } from 'rxjs';


export const msDataResolver: ResolveFn<boolean> = (route, state) => {
  const apiService = inject(ApiService);
  const router = inject(Router);

  //Get depend schools
  const getSchools = (schoolIds: number[]): Observable<ISchool[]> => {
    const schoolParams = {
      path: 'Schools',
      filter: { where: { DepenSchoolID: {inq: schoolIds} } }
    };
  
    return apiService.get<ISchool>(schoolParams).pipe(
      switchMap((schools) => {
        const allSchoolIds:number[] = schools.map(school => school.id!);
  
        if (allSchoolIds.length === 0)
          return of(schools);  // No more children school, ends execution
  
        return getSchools(allSchoolIds).pipe(
          map(childSchools => [...schools, ...childSchools])
        );
      }),
    );
  }

  const userId = localStorage.getItem('currentUser');
  if (!userId) {
    console.error(`No "current user" field found in local storage`);
    router.navigate(['/']);
    return false;
  }

  const userParams = {
    path: 'Userapps',
    filter: {
      where: { id: userId },
     include: [{ roleMappings: ['school'] }],
    },
  };

  const childRoute = route.firstChild?.routeConfig?.path;
  return apiService.get<IUser>(userParams).pipe(
    map((users) => users[0]),
    switchMap((userInfo) => {
      const capabilityParams = {
        path: 'platformModules',
        filter: {
          where: {
            and: [
              { parentRoute: 'manage-platform' },
              { childRoute: childRoute },
            ],
          },
          include: ['Capability'],
        },
      };

      return apiService.get<IPlatformModule>(capabilityParams).pipe(
        map((platformModules:IPlatformModule[]) => ({userInfo, platformModules}))
      );
    }),
    switchMap(({userInfo, platformModules}) => {
      const { roleMappings, ...user } = userInfo;
      return getSchools([roleMappings![0].SchoolID]).pipe(
        tap(schools =>{
          const capabilities = platformModules.map(e => e.Capability).flat();
          apiService.userRoles.set(capabilities);

          const canSee = apiService.hasCapability({ canSee: true})
          if (!canSee) {
            router.navigate(['/']);
            return;
          }

          const sortSchools = [...schools, roleMappings![0].school!].sort((a, b) => a.NameTSchool!.localeCompare(b.NameTSchool!));
          apiService.userInfo.set(user);
          apiService.schools.set(sortSchools);
        }),map(() => true)
      )
    }),
    catchError((error) => {
      console.error(`Error fetching user: ${error.message || 'Internal server error'}`);
      router.navigate(['/']);
      return of(false);
    })
  );
};