<div class="roles">
  <div class="roles-tabs">
    <div class="tab__list">
      @for (tab of ROLE_TABS; track tab.id) {
      <div class="tab__item">
        <input type="text"
          (change)="selectedTab.set(tab.id)"
          [id]="tab.id"
          [value]="tab.label"
          [checked]="selectedTab() === tab.id"
          name="tab"
          type="radio"
          hidden
        />
        <label class="tab__label" [for]="tab.id">{{tab.label}}</label>
      </div>
      }
    </div>
  </div>
  <div>
    @if (selectedTab() === 'modulos') {
      <app-modules></app-modules>
    }@else {
      <app-capabilities></app-capabilities>
    }
  </div>
  
</div>